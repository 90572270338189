import { Component } from "@angular/core";
import { AppBaseComponent } from "./app-base-component";
import { MatTableDataSource } from "@angular/material/table";
import { Table } from 'primeng/table';

@Component({
    template: '',
})
export abstract class PagedLastBaseComponent extends AppBaseComponent {

  dataSource  = new MatTableDataSource<any>()

  displayedColumns: any[] = [];

  pageNumber:number = 0;
  pageSize:number = 0;
  totalElements:number = 0;


  constructor() {
      super()
  }


  getDataList(): void{};

  // CLEAR TABLE FILTERS
  clearTableFilters(table: Table, globalSearchInput?) {
    table.clear(); // Clear Filters

    if(globalSearchInput)
      globalSearchInput.value = null // Clear Global Search Input
  }

}

import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient, HttpBackend } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationService } from './core/authentication';
import { AppHttpInterceptor, HttpErrorInterceptor } from './core/interceptors';
import { NotificationService } from './core/services/notification.service';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';




// AoT requires an exported function for factories
export function HttpLoaderFactory(httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(httpBackend, [
    './assets/i18n/menu/',
    './assets/i18n/auth/',
    './assets/i18n/common/',

    './assets/i18n/modules/customer-management/',
    './assets/i18n/modules/order-management/',
    './assets/i18n/modules/inventory-management/',
    './assets/i18n/modules/product-catalogue/',
    './assets/i18n/modules/system-management/',
    './assets/i18n/modules/payment-management/',
    './assets/i18n/modules/billing/',
    './assets/i18n/modules/dunning-management/',
    './assets/i18n/modules/pocs/',
    './assets/i18n/modules/reports/',
    './assets/i18n/modules/provision-management/',
    './assets/i18n/modules/suspendedCDRs/',
  ]);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    SharedModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      }
    }),
  ],
  providers: [
    DatePipe,
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      deps: [NotificationService, AuthenticationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { environment } from '../../../../environments/environment';

const domainUrl = `${environment.APIUrl}`;
const billingUrl = `${environment.BillingUrl}`;
const documentUrl = `${environment.DocumentManagementUrl}`;
const batchManagementUrl = `${environment.BatchManagement}`;
const inventoryManagementUrl = `${environment.InventoryManagement}`;
const executionMapUrl = `${environment.executionMap}`;
const DOTMOCK = `https://4129f3b3-2072-454a-9564-a800dbacb831.mock.pstmn.io`;

export const API_URLS = {
  /* Static JSON files */
  REGEX: `config/regex-config.json`,

  /** Lookups */
  COUNTRIES: `${domainUrl}/common/country/`,
  CITIES: `${domainUrl}/common/city/`,
  NATIONALTY: `${domainUrl}/common/nationality/`,
  DISPUTED_REASONS: `${DOTMOCK}/lookupMaps/disputeReasons`,

  /** Individual */
  CREATE_CUSTOMER: `${domainUrl}/customerManager/individualApi/`,
  FETCH_BILLING_CYCLE: `${domainUrl}/customerManager/partyBillingCycle/`,
  FETCH_BILLING_PERIOD: `${domainUrl}/customerManager/partyBillSpec/`,
  FETCH_CUSTOMER: (id) =>
    `${domainUrl}/customerManager/individual/cardNr/${id}`,
  FETCH_CUSTOMER_ORDERS: (id) =>
    `${domainUrl}/masterOrder/customerProductOrder/partyRole/${id}`,
  FETCH_ORDER: (id) => `${domainUrl}/masterOrder/customerProductOrder/${id}`,
  FETCH_CUSTOMER_PRODUCTS: (id) =>
    `${domainUrl}/customerManager/customerProduct/customer/${id}/allProducts`,
  FETCH_ACCOUNTS: (id) =>
    `${domainUrl}/customerManager/customer/${id}/accounts`,

  /** CUSTOMER MANAGEMENT */
  GET_BATCH_PROCESSED_FILE: `${batchManagementUrl}/processedFile/`,
  FETCH_BATCH_SUSPEND_RESUME: `${batchManagementUrl}/batchSuspendResume`,
  CREATE_BATCH_SUSPEND_RESUME: `${batchManagementUrl}/start/batch`,
  GET_BATCH_FILE_DOCUMENT_SERVICE: `${documentUrl}/`,
  UPLOAD_BATCH_SUSPEND_RESUME_FILE: `${documentUrl}/upload`,
  DELETE_BATCH_SUSPEND_RESUME_FILE: `${documentUrl}/`,
  VERIFY_BATCH_SUSPEND_RESUME_FILE: `${batchManagementUrl}/start/upload/`,
  CREATE_CORPORATE: `${domainUrl}/masterOrder/masterOrder`,

  /**Billing */
  FETCH_BILLS: `${billingUrl}/billCycleConfig`,
  FETCH_BILLS_CYCLE: `${billingUrl}/billCycle`,
  CREATE_BILL: `${billingUrl}/billCycleConfig`,
  CREATE_BILLS_CYCLE: `${billingUrl}/billCycle`,
  FETCH_Marketing_Message_List: `${billingUrl}/marketingMessage`,
  CREATE_Marketing_Message: `${billingUrl}/marketingMessage`,
  FETCH_TAX_ROUNDING: `${billingUrl}/taxRoundingMethod`,
  CREATE_TAX_ROUNDING: `${billingUrl}/taxRoundingMethod`,
  FETCH_BILLING_TAX_LIST: `${billingUrl}/taxGroup`,
  CREATE_BILLING_TAX_LIST: `${billingUrl}/taxGroup`,
  FETCH_BILL_MEDIUM_List: `${billingUrl}/billMedium`,
  TAX_PLAN: `${billingUrl}/taxPlan`,
  CREATE_BILL_MEDIUM_List: `${billingUrl}/billMedium`,
  FETCH_AGGREGATOR_ATTRIBUTES_LIST: `${billingUrl}/aggregatorAttributes`,
  CREATE_AGGREGATOR_ATTRIBUTES_LIST: `${billingUrl}/aggregatorAttributes`,
  FETCH_BILLING_DISCOUNT_ATTRIBUTES: `${billingUrl}/billingDiscountAttributes`,
  CREATE_BILLING_DISCOUNT_ATTRIBUTES: `${billingUrl}/billingDiscountAttributes`,
  FETCH_BILLING_SYSTEM_CONFIGURATION: `${billingUrl}/billingSystemConfiguration`,
  FETCH_BILLING_DISCOUNT_SCHEMA: `${billingUrl}/discountSchema`,
  FETCH_BILLING_DISCOUNT_SCHEMA_CONDITION: `${billingUrl}/discountSchemaConditions`,
  FETCH_BILLING_DISCOUNT_SCHEMA_FACTOR: `${billingUrl}/discountSchemaFactor`,
  FETCH_BILLING_RUN_ACCOUNT_SEGMENTS: `${billingUrl}/billCycleAccounts`,
  CREATE_DISTRIPUTION_SEGMENT_PROCESS: `${billingUrl}/distributionSegmentProcess`,
  FETCH_BILLING_PROCESS_AUTOMATION: `${billingUrl}/billingProcesses`,
  FETCH_APPLY_FIXED_TAX_ON_INVOICE_LEVEL: `${billingUrl}/fixedTax`,
  FETCH_EXCHANGE_RATE_BASED_ON_CURRENCY: `${billingUrl}/exchangeRate`,
  FETCH_ROUNDING_SETUP_FOR_DIFFERENT_CURRENCIES_LIST: `${billingUrl}/roundingSetup`,
  FETCH_CUSTOMER_BILLS: `${billingUrl}/queryDownloadCustomerBill`,
  FETCH_LOGS: `${billingUrl}/sse/receive`,
  FETCH_LOGS_PROCESS: (process) => `${billingUrl}/logs/${process}`,
  DOWNLOAD_LOGS_PROCESS: (process) => `${billingUrl}/logs/file/${process}`,

  //  Building Lookups
  BILLING_LOOKUPS: (code: string) =>
    `${billingUrl}/lookup/enum-value/enum-code/${code}`,
  FETCH_ALL_BILLING_LOOKUPS: `${billingUrl}/lookup/enumeration`,
  FETCH_ALL_BILLING_LOOKUPS_VALUES: `${billingUrl}/lookup/enum-value`,

  /**Subscription */
  FETCH_PRODUCT_OFFERS: (productLineId) =>
    `${domainUrl}/productCatalog/productOffering/?productLineId=${productLineId}&productCategoryId=3&isPrimary=true`,
  PRODUCT_OFFERS: `${domainUrl}/productCatalog/productOffering/`,
  FETCH_SUBSCRIPTION: (entitySpecificationId, warehouseId) =>
    `${domainUrl}/resourceInventory/warehouse/${warehouseId}/entityspecification/${entitySpecificationId}/stockitem?limit=20&offset=0`,
  BOOK_RESOURCE: `${domainUrl}/resourceInventory/resource/stockItemRequest`,
  CREATE_ORDER: `${domainUrl}/masterOrder/masterOrder`,
  GENRTATE_RECIEPT: `${domainUrl}/invoices/generator/receipt?lang=en`,
  GET_PRODUCT_LINES: `${domainUrl}/productCatalog/productLine/`,

  /** INVENTORY MANAGEMENT */
  // [00] COMMON
  FETCH_COUNTRIES: `${domainUrl}/common/country/`,
  FETCH_CITIES: `${domainUrl}/common/city/`,

  // [01] WAREHOUSE
  FETCH_WAREHOUSES: `${inventoryManagementUrl}/warehouse/`,
  FETCH_WAREHOUSES_STOCKLEVEL: (id) =>
    `${inventoryManagementUrl}/warehouse/${id}/stockitemstocklevel`,

  // [02] STOCK ITEMS
  STOCK_ITEMS: `${inventoryManagementUrl}/resourceSpec/stockItem`,
  CREATE_STOCK_IN: `${inventoryManagementUrl}/resource/stockItemRequest`,

  // [03] STOCK LEVELS
  STOCK_levels: `${inventoryManagementUrl}/stockItemStockLevel/`,

  // [04] RESOURCES
  RESOURCES: `${domainUrl}/resourceCatalog/resourceSpecification/`,
  //  [04] Batch Operation
  BATCH_OPERATION: `${inventoryManagementUrl}/warehouse`,
  //  [05]  Query Batch Operation
  QUERY_BATCH_OPERATION: `${inventoryManagementUrl}/stockItemBatch`,
  //  [06]  Query Batch Operation
  DOWNLOAD_BATCH_FIILE: `${inventoryManagementUrl}/stockItemBatch/file`,

  // TRANSFER ITEM
  TRANSFER_ITEM: `${inventoryManagementUrl}/stockItemRequest/transfer`,
  STOCK_LOCATION: `${inventoryManagementUrl}/stockLocation`,

  /* ORDER MANAGEMENT */
  EXECUTION_MAP: executionMapUrl,

  // ORGANIZATION
  ORGANIZATION_DATA: `${domainUrl}/customerManager/organization`,
  ORGANIZATION_CUSTOMERS: `${domainUrl}/customerManager/partyRole/partyRoleOrgApi`,
  ORGANIZATION_ALL_ACCOUNTS: `${domainUrl}/customerManager/account/getAllAccountsByPartyRoleId`,
  ORGANIZATION_GROUPS: `${domainUrl}/customerManager/partyGroup/getAllByPartyId`,

  // GENERAL API
  WAREHOUSE_WITH_ORGUNITID: (id: number) =>
    `${domainUrl}/resourceInventory/warehouse/?orgUnitId=${id}`,

  // UnbilledDetails
  UNBILLED_USAGE_DETAILS:(msisdn: number, invoiceAccountID: number, useStartDate: String, useEndDate: String) =>
  `${billingUrl}/unbilled/usage/msisdn/${msisdn}/invoiceAccountID/${invoiceAccountID}/startDate/${useStartDate}/endDate/${useEndDate}`,

  UNBILLED_OFFER_DETAILS:(msisdn: number, invoiceAccountID: number, useStartDate: String, useEndDate: String) =>
  `${billingUrl}/unbilled/offer/msisdn/${msisdn}/invoiceAccountID/${invoiceAccountID}/startDate/${useStartDate}/endDate/${useEndDate}`,

  EVENT_LOGS: `${billingUrl}/eventLogs`,
};

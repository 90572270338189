/**
 * REGEX FORMATS
 */
const ALFA_FORMAT: RegExp = /^[a-zA-Z\s]+$/;
const ALFA_NUM_FORMAT: RegExp = /^[a-zA-Z0-9\s,]+$/;
const NUMBER_FORMAT: RegExp = /^[0-9]+$/;
const FLOAT_NUMBER_FORMAT: RegExp = /^[+]?\d*\.?\d+$/;
const USERNAME_FORMAT: RegExp = /^[a-zA-Z0-9]+$/;
// const MAIL_FORMAT: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$/;
const MAIL_FORMAT: RegExp = /^[a-zA-Z0-9._@]/;


// EXPORT ALL FORMATS
export const REGEX_FORMATS = {
  ALFA: ALFA_FORMAT,
  ALFA_NUM: ALFA_NUM_FORMAT,
  NUMBER: NUMBER_FORMAT,
  FLOAT: FLOAT_NUMBER_FORMAT,
  USERNAME: USERNAME_FORMAT,
  MAIL: MAIL_FORMAT,
}



import { environment } from "src/environments/environment";

export const authConfig = {
  issuer: `${environment.keycloackURL}/auth/realms/dot-space`,
  redirectUri: `${window.location.origin}`,
  postLogoutRedirectUri: window.location.origin,
  clientId: "dot-PKCE-client",
  responseType: "code",
  scope: 'openid profile',
  state:'pkceState',
  // at_hash is not present in JWT token
  disableAtHashCheck: true,
  showDebugInformation: true,
  silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,

  // defaults to true for implicit flow and false for code flow
  // as for code code the default is using a refresh_token
  // Also see docs section 'Token Refresh'
  useSilentRefresh: true,
};

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { config } from '@shared/utilities';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { AccessPermissionsGuard } from '@core/guards/access-permissions.guard';

// GUARD SWITCHER
const enableGuards = true
import { ROUTERS_PARENTS } from 'src/config/permissions/routes/parents_routes';
import { ErrorsLayoutComponent } from './errors/errors-layout/errors-layout.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: config.home.name,
  //   pathMatch: 'full',
  // },

  {
    path: config.home.name,
    canActivate: enableGuards? [AccessPermissionsGuard]: [() => true],
    data: { route: config.home.route, requiredPermissions: ROUTERS_PARENTS.HOME },
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/home-page/home-page.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: config.individual.name,
    data: { route: config.individual.name },
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/individual/individual.module').then(
        (m) => m.IndividualModule
      ),
  },
  {
    path: config.employees.name,
    data: { route: config.employees.name, employee: true },
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/individual/individual.module').then(
        (m) => m.IndividualModule
      ),
  },
  {
    path: config.customerDetails.name,
    data: { route: config.customerDetails.name },
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/customer-details/customer-details.module').then(
        (m) => m.CustomerDetailsModule
      ),
  },
  {
    path: config.billing.name,
    canActivate: enableGuards? [AccessPermissionsGuard]: [() => true],
    data: { route: config.billing.name, requiredPermissions: ROUTERS_PARENTS.BILLING_MANAGEMENT_ROUTE_PERMISSIONS},
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/bill-configuration/bill-configuration.module').then(
        (m) => m.BillConfigurationModule
      ),
  },
  {
    path: config.runBilling.name,
    data: { route: config.runBilling.name },
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/run-billing/run-billing.module').then(
        (m) => m.RunBillingModule
      ),
  },
  {
    path: config.unbilledDetails.name,
    data: { route: config.unbilledDetails.name },
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/unbilled-details/unbilled-details.module').then(
        (m) => m.UnbilledDetailsModule
      ),
  },
  {
    path: config.dunningManagement.name,
    data: { route: config.dunningManagement.name },
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/dunning-management/dunning-management.module').then(
        (m) => m.DunningManagementModule
      ),
  },
  {
    path: config.suspendedCDRs.name,
    data: { route: config.suspendedCDRs.name },
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/suspended-cdrs/suspended-cdrs.module').then(
        (m) => m.SuspendedCdrsModule
      ),
  },
  {
    path: config.eventLogs.name,
    data: { route: config.eventLogs.name },
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/event-logs/event-logs.module').then(
        (m) => m.EventLogsModule
      ),
  },
  
  {
    path: config.dunningManagement.name,
    data: { route: config.dunningManagement.name },
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/dunning-management/dunning-management.module').then(
        (m) => m.DunningManagementModule
      ),
  },
  {
    path: config.suspendedCDRs.name,
    data: { route: config.suspendedCDRs.name },
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/suspended-cdrs/suspended-cdrs.module').then(
        (m) => m.SuspendedCdrsModule
      ),
  },
  {
    path: config.billingMonitoring.name,
    data: { route: config.billingMonitoring.name },
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/billing-monitoring/billing-monitoring.module').then(
        (m) => m.BillingMonitoringModule
      ),
  },
  {
    path: config.customerManagement.name,
    canActivate: enableGuards? [AccessPermissionsGuard]: [() => true],
    data: { route: config.customerManagement.name, requiredPermissions: ROUTERS_PARENTS.CUSTOMER_MANAGEMENT_ROUTE_PERMISSIONS},
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/customer-management/customer-management.module').then(
        (m) => m.CustomerManagementModule
      ),
  },
  {
    path: config.productCatalogue.name,
    canActivate: enableGuards? [AccessPermissionsGuard]: [() => true],
    data: { route: config.productCatalogue.name, requiredPermissions: ROUTERS_PARENTS.PRODUCT_CATALOG_ROUTE_PERMISSIONS},
    component: MainLayoutComponent,
    loadChildren: () =>
      import('@components/product-catalogue/product-catalogue.module').then(
        (m) => m.ProductCatalogueModule
      ),
  },
  {
    path: config.inventoryManagement.name,
    canActivate: enableGuards? [AccessPermissionsGuard]: [() => true],
    data: { route: config.inventoryManagement.name, requiredPermissions: ROUTERS_PARENTS.INVENTORY_MANAGEMENT_ROUTE_PERMISSIONS},
    component: MainLayoutComponent,
    loadChildren:()=> import('@components/inventory-management/inventory-management.module').then((m) => m.InventoryManagementModule)
  },
  {
    path: config.orderManagement.name,
    canActivate: enableGuards? [AccessPermissionsGuard]: [() => true],
    data: { route: config.orderManagement.name, requiredPermissions: ROUTERS_PARENTS.ORDER_MANAGEMENT_ROUTE_PERMISSIONS},
    component: MainLayoutComponent,
    loadChildren:()=> import('@components/order-management/order-management.module').then((m) => m.OrderManagementModule)
  },
  {
    path: config.systemManagement.name,
    canActivate: enableGuards? [AccessPermissionsGuard]: [() => true],
    data: { route: config.systemManagement.name, requiredPermissions: ROUTERS_PARENTS.SYSTEM_MANAGEMENT_ROUTE_PERMISSIONS },
    component: MainLayoutComponent,
    loadChildren:()=> import('@components/system-management/system-management.module').then((m) => m.SystemManagementModule)
  },
  {
    path: config.pocs.name,
    data: { route: config.pocs.name },
    component: MainLayoutComponent,
    loadChildren:()=> import('@components/pocs/pocs.module').then((m) => m.PocsModule)
  },
  {
    path: config.reports.name,
    data: { route: config.reports.name },
    component: MainLayoutComponent,
    loadChildren:()=> import('@components/reports/reports.module').then((m) => m.ReportsModule)
  },
  {
    path: config.organizations.name,
    data: { route: config.organizations.name },
    component: MainLayoutComponent,
    loadChildren:()=> import('@components/organization/organization.module').then((m) => m.OrganizationModule)
  },
  {
    path: config.paymentManagement.name,
    data: { route: config.paymentManagement.name },
    component: MainLayoutComponent,
    loadChildren:()=> import('@components/payment-management/payment-management.module').then((m) => m.PaymentManagementModule)
  },
  {
    path: config.provision.name,
    data: { route: config.provision.name },
    component: MainLayoutComponent,
    loadChildren:()=> import('@components/provision/provision.module').then((m) => m.ProvisionModule)
  },
  {
    path: 'errors',
    component: ErrorsLayoutComponent,
    loadChildren:()=> import('./errors/errors.module').then((m) => m.ErrorsModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

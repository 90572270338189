import { Component, Input, Output, EventEmitter, OnChanges, ElementRef, Renderer2 } from '@angular/core';
import { MenuItem } from '@shared/interfaces';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import _ from 'lodash';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent {

  // INPUTS
  @Input() menuItem: MenuItem

  // OUTPUTS
  @Output() deSeclectMenu: EventEmitter<any> = new EventEmitter<any>();

  // FILTERS
  menuItemsFilterControl: FormControl = new FormControl('');
  menuItemsFilter: MenuItem[] = [];

  onScroll(){
    event.stopPropagation();
  }

  onLinkClicked() {
    document.querySelector('body').style.overflow = 'auto';
    // this.menuItem.label = null
    // Disable the Side Menu
    this.deSeclectMenu.emit()
  }

  constructor(private renderer: Renderer2){

    // MENU FILTER
    this.menuItemsFilterControl.valueChanges
    .pipe(
      debounceTime(100),
      distinctUntilChanged(),
    )
    .subscribe((value: string) => {
      const filterValue = value.toLowerCase().trim();
      if (filterValue) {
        this.menuItemsFilter = this.menuItem.subItems.filter(menu => menu.label?.toLocaleLowerCase()?.includes(filterValue));
      } else {
        this.menuItemsFilter = _.cloneDeep(this.menuItem.subItems);
      }
    });

  }

  ngOnChanges(){
    // FILL MENU FILTER
    this.menuItemsFilter = [...this.menuItem?.subItems||[]]
  }

  toggleMenuItem(event){

    // STOP THE PROPAGATION BEHAVIOR
    event.stopPropagation()

    // TOGGLE MENU
    if(event.target.classList.contains("active")){
      this.renderer.removeClass(event.target, "active")
      this.renderer.removeClass(this.renderer.nextSibling(event.target), "active")
    }else{
      this.renderer.addClass(event.target, "active")
      this.renderer.addClass(this.renderer.nextSibling(event.target), "active")
    }
  }
}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '@core/services/http/http.service';
import { LanguageService } from '@core/services/language.service';
import { ToasterService } from '@core/services/toaster/toaster.service';
import { GenericDeleteModel } from '@shared/interfaces/delete-model-interface';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-delete-model',
  templateUrl: './delete-model.component.html',
  styleUrls: ['./delete-model.component.scss'],
})
export class DeleteModelComponent {
  isSubmit = false;
  constructor(
    private dialogRef: MatDialogRef<DeleteModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericDeleteModel,
    private httpService: HttpService,
    private toasterService: ToasterService,
    private languageService: LanguageService,
  ) {}
  closeDialog(data?) {
    this.dialogRef.close(data);
  }

  onSubmit<T>(): void {
    this.isSubmit = true;
    let errorMSg = this.data.errorMsg ? this.data.errorMsg : 'messages.failed';
    this.httpService
      .deleteData(this.data.endPoint, this.data.id)
      .pipe(finalize(() => (this.isSubmit = false)))
      .subscribe({
        next: (res: T) => {
          this.dialogRef.close(res);
          this.toasterService.displaySuccessMessage(
            this.languageService.getTransValue('messages.deletedSuccessfully'),
          );
        },
        error: () => {
          this.toasterService.displayErrorToastr(
            this.languageService.getTransValue(errorMSg),
          );
        },
      });
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const domain = "https://crm-dev.sigmamea.com/api"

export const environment = {
  production: true,
  APIUrl: `${domain}`,
  BillingUrl: 'https://crm-dev.sigmamea.com/billing',
  // BillingUrl: 'http://localhost:9897/billing',
  DocumentManagementUrl: 'https://crm-dev.sigmamea.com/file',
  BatchManagement: 'https://crm-dev.sigmamea.com/batch',
  InventoryManagement: `${domain}/resourceInventory`,
  productCatalog: `${domain}/productCatalog`,
  reportingManagement: `https://crm-dev.sigmamea.com/report`,
  executionMap: `${domain}/masterOrder/getXmlByOrderID`,
  keycloackURL: 'https://keycloak.sigmamea.com',
  systemManagement: `${domain}/omniauth/omni/auth/manager`,
  authorities: `${domain}/omniauth/omni/auth/manager/authority`,
  warehousesIDs: {
    physicalWarehouseId: 552,
    virtualWarehouseId: 2,
    entitySpecification: 311,
  },
  DunningManagementURL: 'https://billing.dot-sigma.com/dunningmanagement',
  suspendedCDRs: 'https://billing.dot-sigma.com/billing/suspendedCDRs',
  BillingExecutorURL: 'http://islamabad-test-billing',
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { MenuItem } from '@shared/interfaces';
import { config } from '@shared/utilities/page-config';
import { DOT_MENU } from 'src/config/permissions/modules/menu';

export const MainItems: MenuItem[] = [
  {
    label: 'customerManagement',
    icon: 'customer.svg',
    permissions: DOT_MENU.CUSTOMER_MANAGEMENT.MENU,
    subItems: [
      {
        icon: 'person_add',
        label: 'menu.createCustomer',
        id: 'create_customer',
        url: config.individual.route,
      },
      {
        icon: 'person_add',
        label: 'menu.createEmployee',
        id: 'createEmployee',
        url: config.employees.route,
      },
      {
        icon: 'cloud',
        label: 'Batch Operation',
        id: 'batchSuspensionResume',
        url: config.customerManagement.batchSuspensionResume.route,
      },
      {
        // icon: 'cloud',
        label: 'menu.createCorporate',
        id: 'createCorporate',
        url: config.customerManagement.creeateCorporate.route,
      },
      // { id: '', label: 'Modify Indvidual' },
      // { id: '', label: 'Modify Organization' },
      // { id: '', label: 'New subscribtion' },
      // { id: '', label: 'Search Customer' },
      // { id: '', label: 'Manage Groups' },
    ],
  },
  {
    label: 'inventoryManagement.title',
    icon: 'inventory.svg',
    permissions: DOT_MENU.INVENTORY_MANAGEMENT.MENU,
    subItems: [
      {
        id: '',
        label: 'menu.inventoryManagement.manageWarehouses',
        url: config.inventoryManagement.warehouse.route,
      },
      {
        id: '',
        label: 'menu.inventoryManagement.stockItems',
        url: config.inventoryManagement.stockItems.route,
      },
      {
        id: '',
        label: 'menu.inventoryManagement.stockLevels',
        url: config.inventoryManagement.stockLevels.route,
      },
      {
        id: '',
        label: 'menu.inventoryManagement.resources',
        url: config.inventoryManagement.resources.route,
      },
      {
        id: '',
        label: 'menu.inventoryManagement.resourcesSpecifications',
        url: config.inventoryManagement.resourceSpecifications.route,
      },
      {
        id: '',
        label: 'menu.inventoryManagement.batchOperation',
        url: config.inventoryManagement.bathcOperation.route,
      },
      {
        id: '',
        label: 'menu.inventoryManagement.itemTransfer',
        url: config.inventoryManagement.itemTransfer.route,
      },
      {
        id: '',
        label: 'menu.inventoryManagement.receiveItem',
        url: config.inventoryManagement.itemReceive.route,
      },
      {
        id: '',
        label: 'menu.inventoryManagement.reserve',
        url: config.inventoryManagement.reserve.route,
      },
      {
        id: '',
        label: 'menu.inventoryManagement.unreserve',
        url: config.inventoryManagement.unreserve.route,
      },
      {
        id: '',
        label: 'menu.inventoryManagement.WarehousesTransferEligibility',
        url: config.inventoryManagement.warehouseTransferEligibility.route,
      },
      {
        id: '',
        label: 'menu.inventoryManagement.warehouseCategoryTransferEligibility',
        url: config.inventoryManagement.warehouseCategoryTransferEligibility
          .route,
      },
      {
        id: '',
        label: 'menu.inventoryManagement.changeResourceStatus',
        url: config.inventoryManagement.changeResourceStatus.route,
      },
    ],
  },
  {
    label: 'orderManagement',
    icon: 'order.svg',
    permissions: DOT_MENU.ORDER_MANAGEMENT.MENU,
    subItems: [
      {
        id: '',
        label: 'Query Order',
        url: config.orderManagement.queryOrder.route,
      },
      {
        id: 'BPMN_POC_MODELER',
        label: 'Workflow Configurator',
        url: config.pocs.bpmnPocModeler.route,
      },
      {
        id: 'Manage Business Rules',
        label: 'Manage Business Rules',
        url: config.pocs.dmnPocModeler.route,
      },
      // {
      //   id: '',
      //   label: 'BPMN Viewer',
      //   url: config.pocs.bpmnPoc.route,
      // },
      // { id: '', label: 'Change MSISDN' },
      // { id: '', label: 'Change status - deactive' },
      // { id: '', label: 'Change status - suspend/resume' },
      // { id: '', label: 'Change status - bar/unbar' },
    ],
  },
  {
    label: 'productCatalogue.title',
    icon: 'product.svg',
    permissions: DOT_MENU.PRODUCT_CATALOG.MENU,
    subItems: [
      // {
      //   id: '',
      //   label: 'menu.productCatalogue.createProductOffer',
      //   url: config.productCatalogue.createOffer.route,
      // },
      // {
      //   id: '',
      //   label: 'Create Offer POC',
      //   url: '/product-catalogue/create-offer-poc',
      // },
      // {
      //   id: '',
      //   label: 'Product Catalog POC',
      //   url: '/product-catalogue/product-catalog-poc',
      // },
      {
        id: '',
        label: 'Product Designer',
        url: config.productCatalogue.productCatalogueI.route,
      },
      // { id: '', label: 'Create appendant product' },
      // { id: '', label: 'Create Product Offer' },
      // { id: '', label: 'Modify Product' },
      // { id: '', label: 'Create plan' },
      // { id: '', label: 'Modify plan' },
      // { id: '', label: 'Suspend Offer' },
      // { id: '', label: 'Create Agreement' },
    ],
  },
  {
    label: 'provision.title',
    icon: 'provision.svg',
    permissions: DOT_MENU.PROVISION.MENU,
    subItems: [
      {
        id: '',
        label: 'menu.provision.serviceCatalogDesigner',
        url: config.provision.serviceCatalogDesigner.route,
      },
      {
        id: '',
        label: 'menu.provision.pendingFailedOrders',
        url: config.provision.pendingFailedOrders.route,
      },
      {
        id: '',
        label: 'menu.provision.parametersManagement',
        url: config.provision.parametersManagement.route,
      },
      {
        id: '',
        label: 'menu.provision.sourceSystmes',
        url: config.provision.sourceSystmes.route,
      },
      {
        label: 'menu.provision.actionManagement',
        url: config.provision.actionManagement.route,
      },
      {
        id: '',
        label: 'menu.provision.servicesManagement',
        url: config.provision.servicesManagement.route,
      },
      {
        id: '',
        label: 'menu.provision.networkElementsManagement',
        url: config.provision.networkElementsManagement.route,
      }
    ],
  },
  {
    label: 'paymentManagement.title',
    icon: 'payment.svg',
    permissions: DOT_MENU.PAYMENT_MANAGEMENT.MENU,
    subItems: [
      {
        id: '',
        label: 'menu.paymentManagement.createCashbox',
        url: config.paymentManagement.createCashbox.route,
      },
      {
        id: '',
        label: 'menu.paymentManagement.openCloseCashbox',
        url: config.paymentManagement.openCloseCashbox.route,
      },
      {
        id: '',
        label: 'menu.paymentManagement.adjustCashbox',
        url: config.paymentManagement.adjustCashbox.route,
      },
      {
        id: '',
        label: 'menu.paymentManagement.archiveCashbox',
        url: config.paymentManagement.archiveCashbox.route,
      },
      {
        id: '',
        label: 'menu.paymentManagement.queryCashbox',
        url: config.paymentManagement.queryCashbox.route,
      },
      // {
      //   id: '',
      //   label: 'Payment reversal',
      //   url: config.paymentManagement.reverseCustomerPayment.route,
      // },
    ],
  },
  {
    label: 'systemManagement.title',
    icon: 'system.svg',
    permissions: DOT_MENU.SYSTEM_MANAGEMENT.MENU,
    subItems: [
      {
        id: 'systemManagement_Users',
        label: 'menu.systemManagement.users',
        url: config.systemManagement.users.route,
      },
      {
        id: 'systemManagement_Roles',
        label: 'menu.systemManagement.businessRoles',
        url: config.systemManagement.businessRoles.route,
      },
      {
        id: 'systemManagement_Roles',
        label: 'menu.systemManagement.roles',
        url: config.systemManagement.roles.route,
      },
      {
        id: 'systemManagement_Departments',
        label: 'menu.systemManagement.departments',
        url: config.systemManagement.departments.route,
      },
      {
        id: 'systemManagement_OrganizationUnits',
        label: 'menu.systemManagement.organizationUnits',
        url: config.systemManagement.organizationUnits.route,
      },
      {
        id: 'systemManagement_SystemLogs',
        label: 'menu.systemManagement.systemLogs',
        url: config.systemManagement.systemLogs.route,
      },
      {
        id: 'systemManagement_UserSessions',
        label: 'menu.systemManagement.usersSessions',
        url: config.systemManagement.usersSessions.route,
      },
      // {
      //   label: 'menu.systemManagement.frontendControls.title',
      //   icon: 'product.svg',
      //   permissions: DOT_MENU.SYSTEM_MANAGEMENT.MENU,
      //   subItems: [
      //     {
      //       id: '',
      //       label: 'menu.systemManagement.frontendControls.microservices',
      //       url: config.systemManagement.frontendControls.microservices.route,
      //     },
      //     {
      //       id: '',
      //       label: 'menu.systemManagement.frontendControls.pages',
      //       url: config.systemManagement.frontendControls.pages.route,
      //     },
      //     {
      //       id: '',
      //       label: 'menu.systemManagement.frontendControls.subPages',
      //       url: config.systemManagement.frontendControls.subPages.route,
      //     },
      //     {
      //       id: 'systemManagement_Attributes',
      //       label: 'menu.systemManagement.attributes',
      //       url: config.systemManagement.attributes.route,
      //     },
      //     // {
      //     //   id: 'testTable',
      //     //   label: 'Test Table',
      //     //   url: config.systemManagement.frontendControls.testTable.route,
      //     // },
      //   ],
      // },
    ],
  },
  {
    label: 'billing.title',
    icon: 'billing.svg',
    permissions: DOT_MENU.BILLING_MANAGEMENT.MENU,
    subItems: [
      {
        id: '',
        label: 'menu.billing.billingSystemConfiguration',
        url: config.billing.billingSystemConfiguration.route,
      },
      {
        id: '',
        label: 'menu.billing.enumeration',
        url: config.billing.enumeration.route,
      },
      { id: '', label: 'menu.billing.billCycle', url: config.billing.route },
      {
        id: '',
        label: 'menu.billing.billMedium',
        url: config.billing.configureBillMedium.route,
      },
      {
        id: '',
        label: 'menu.billing.marketingMessage',
        url: config.billing.configureMarketingMessages.route,
      },
      // { id: '',
      //   label: 'menu.billing.taxRounding',
      //   url: config.billing.taxRoundingMethod.route
      // },
      {
        id: '',
        label: 'menu.billing.taxInformation',
        url: config.billing.configureBillingTax.route,
      },
      // {
      //   id: '',
      //   label: 'menu.billing.applyFixedTax',
      //   url: config.billing.applyFixedTaxOnInvoiceLevel.route,
      // },
      // { id: '', label: 'configure common billing rules' },
      {
        id: '',
        label: 'menu.billing.discountAttributes',
        url: config.billing.billingDiscountAttributes.route,
      },
      {
        id: '',
        label: 'menu.billing.discountSchema',
        url: config.billing.billingDiscountSchema.route,
      },
      // {
      //   id: '',
      //   label: 'menu.billing.accountSegments',
      //   url: config.billing.configureBillRunAccountSegments.route
      // },
      {
        id: '',
        label: 'menu.billing.taxPlan',
        url: config.billing.taxPlan.route,
      },
      // {
      //   id: '',
      //   label: 'menu.billing.processAutomation',
      //   url: config.billing.configueBillingProcessAutomation.route,
      // },
      // {
      //   id: '',
      //   label: 'menu.billing.aggregatorAttributes',
      //   url: config.billing.configureAggregatorAttributes.route,
      // },
      {
        id: '',
        label: 'menu.billing.currencyRounding',
        url: config.billing.configureRoundingForDifferentCurrencies.route,
      },
      {
        id: '',
        label: 'menu.billing.exchangeRate',
        url: config.billing.configureExchangeRateBasedBillCurrency.route,
      },
      // { id: '', label: 'Run billing ', url: config.runBilling.route },
      // {
      //   id: '',
      //   label: 'Execute Regular Bill',
      //   url: config.billing.executeRegularBill.route,
      // },
      // {
      //   id: '',
      //   label: 'Download customer generated bills',
      //   url: config.billing.queryAndDownloadCustomerBill.route,
      // },
      {
        id: '',
        label: 'menu.suspendedCDRs.aggregatorSuspendedCDRs',
        url: config.suspendedCDRs.aggregatorSuspendedCDRs.route,
      },
      {
        id: '',
        label: 'menu.suspendedCDRs.discountSuspendedCDRs',
        url: config.suspendedCDRs.discountSuspendedCDRs.route,
      },
      {
        id: '',
        label: 'menu.suspendedCDRs.taxationSuspendedCDRs',
        url: config.suspendedCDRs.taxationSuspendedCDRs.route,
      },
      {
        id: '',
        label: 'menu.billingMonitoring.billAnalytics',
        url: config.billingMonitoring.billAnalytics.route,
      },
      {
        id: '',
        label: 'menu.billingMonitoring.billExecutor',
        url: config.billingMonitoring.billExecutor.route,
      },
      {
        id: '',
        label: 'menu.billing.unbilledOfferDetails',
        url: config.unbilledDetails.unbilledOfferDetails.route,
      },
      {
        id: '',
        label: 'menu.billing.unbilledUsageDetails',
        url: config.unbilledDetails.unbilledUsageDetails.route,
      },
      {
        id: '',
        label: 'menu.eventLogs.title',
        url: config.eventLogs.viewEventLogs.route,
      },

    ],
  },
  {
    label: 'reports.title',
    icon: 'reports.svg',
    permissions: DOT_MENU.REPORTING_MANAGEMENT.MENU,
    subItems: [
      {
        id: 'inventorySummaryReport',
        label: 'menu.reports.inventorySummaryReport',
        url: config.reports.inventorySummaryReport.route,
      },
      {
        id: 'querySimReport',
        label: 'menu.reports.querySimReport',
        url: config.reports.querySimReport.route,
      },
    ],
  },
  {
    label: 'dunningManagement.title',
    icon: 'dunning.svg',
    permissions: DOT_MENU.DUNNING_MANAGEMENT.MENU,
    subItems: [
      {
        id: 'dunningEnumeration',
        label: 'menu.dunningManagement.dunningEnumeration',
        url: config.dunningManagement.dunningEnumeration.route,
      },
      // {
      //   id: 'dunningEnumerationValues',
      //   label: 'menu.dunningManagement.dunningEnumerationValues',
      //   url: config.dunningManagement.dunningEnumerationValues.route,
      // },
      {
        id: 'querySimReport',
        label: 'menu.dunningManagement.viewAllRules',
        url: config.dunningManagement.viewAllRules.route,
      },
    ],
  },
];
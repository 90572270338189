<dot-modal
  [title]="
    ('btn.delete' | translate) + ' ' + (this.data.modelTitle | translate)
  "
  (modalClosed)="closeDialog()"
  [isDeleteModal]="true"
  (confirmDeleteFn)="onSubmit()"
  [confirmDeleteBtnLabel]="'btn.delete' | translate"
>
  <!-- START OF MODAL BODY -->
  <ng-container class="modal-body">
    <p>
      {{ "messages.deleteMessage" | translate }}
      {{ this.data.name | translate }} {{ "messages.withId" | translate }} ({{
        data.id
      }})
    </p>
  </ng-container>
  <!-- END OF MODAL BODY -->
</dot-modal>
